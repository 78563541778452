<template>
    <div class="container_">
        <div class="topTitle">{{content.name}}</div>
        <div class="content" v-loading="loading">
            <div class="thead">
                <div class="td1">适用范围</div>
                <div class="td2">版本</div>
                <div class="td3">上传时间</div>
                <div class="td4">资料大小</div>
            </div>
            <div class="tr" >
                <div class="tr1">
                    <div class="td1" >{{content.scope}}</div>
                    <div class="td2">{{content.version}}</div>
                    <div class="td3">{{content.uploadTime}}</div>
                    <div class="td4">{{content.size}}</div>
                </div>
                <div class="tr2">
                    {{content.content}}
                </div>
            </div>

            <div class="downloadClass" v-if="content.auth=='0'">
                <el-button :loading="download_loading" type="primary" class="downloadBtn" @click="handleDownload"><i class="el-icon-download"></i> 下 载</el-button>
            </div>
            <div v-else style="margin-top: 20px;">
                <div style="margin-bottom: 10px;">如需下载联系销售，电话如下</div>
                <el-row :gutter="0" style="width:100%" v-for="(item,index) in salePhones" :key="index" class="phoneType" :type="isMoPhone?'':'flex'">
                    <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" style="padding:0px">
                        <div class="typeName">{{item.title}}</div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16" style="padding:0px">
                        <div class="typeContent">
                            <div class="phoneItem" v-for="(t,i) in item.tels" :key="i">{{t}}</div>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <div class="relaResource" v-if="content.relationFileList&&content.relationFileList.length>0">
                <div class="topTitle">相关资料</div>
                <div class="rela_th">
                    <div class="rela_td1">资料名称</div>
                    <div class="rela_td2">资料简介</div>
                </div>
                <div class="rela_tr" v-for="(item,index) in content.relationFileList" :key="index">
                    <div class="rela_td1"  @click="clickResourceName(item)">{{item&&item.name?item.name:''}}</div>
                    <div class="rela_td2">{{item&&item.content?item.content:''}}</div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { productItem, downloadFile, saleSupport} from '@/services/serviceSupport.js'
export default {
  name: "resourceDetail",
  components:{
  },
  props:[
  ],
  data() {
    return {
        windowWidth:window.innerWidth,
        isMoPhone:false,
        loading:false,
        content:{},
        salePhones:[],
        download_loading:false,
    };
  },
  computed: {
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth < 992) {
        this.isMoPhone = true
      }else{
        this.isMoPhone = false
      }
    },
  },
  mounted() {
    if (this.windowWidth < 992) {
        this.isMoPhone = true
    }else{
        this.isMoPhone = false
    }
    if(this.$route.params.name){
       this.getContent(this.$route.params.name)
    }
    console.log('detail的mounted')
    console.log('是否存在英文版参数type---',this.$route.query.type)
  },
  activated(){
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    getContent(name){
        let fileName = ''
        if(String(name).indexOf('.html')<0){
           fileName = name.split('.').join('_')
        }else{
           fileName = (name.split('.html')[0]).split('.').join('_')
        }
        let f = String(fileName).indexOf('.html')<0?fileName+'.html':fileName
        this.content = {}
        this.loading = true;
        
        productItem({fileName:f}).then(res => {
            if(res.data.code===0 && res.data.data){
                this.content = res.data.data
                if(this.content.redirect){
                   window.location.href = this.content.redirect
                   return
                }
                if(this.content.auth=='1'){
                    this.getSaleSupport()
                }
                document.title = this.content.name+' - 南京沁恒微电子股份有限公司'
               
                this.$bus.$emit('left_categoryId',this.content.categoryId)

                if(this.$route.query.type&&this.$route.query.type=='en'){
                    console.log('英文参数，触发自动下载')
                    this.handleDownload()
                }
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
    //销售服务
    getSaleSupport(){
        this.salePhones = []
        this.loading = true;
        saleSupport().then(res => {
            if(res.data.code===0 && res.data.data){
                this.salePhones = res.data.data
                console.log('销售电话---', this.salePhones)
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
    clickResourceName(t){
        let pName = t.name.split('.').join('_')
        this.$router.push({ name: 'ResourceDetail', params: {name:pName+'.html'}})
    },
    //点击下载
    handleDownload(){
        let that = this
        this.download_loading = true
        let fileName = this.content.name
        downloadFile({fileName}).then(res=>{
            console.log('res---',res)
            this.download_loading=false
            if(res.data.message){
                return  this.$message.error(res.data.message)
            }
            if(res.headers["content-type"]&&res.headers["content-type"].indexOf("application/json") > -1){
                
                //非下载请求
                let ress = res.data
                //判断返回数据格式是否为blob
                var isBlob = ress instanceof Blob;
                if (isBlob){
                    //非下载请求下，返回数据格式是blob，是下载文件请求，但是后台返回错误信息json的情况
                    var reader = new FileReader()
                    reader.addEventListener('loadend', function (e) {
                        var json = e.target.result;
                        //把json字符串转为json格式
                        ress = JSON.parse(json);
                        if (ress.code !== 0) {
                           return  that.$message.error(ress.message)
                        } else {
                            return response.data
                        }
                    })
                    reader.readAsText(ress)
                }
            }
            else{
                // let url = window.URL.createObjectURL(new Blob([res.data],{ type: res.headers["content-type"] }))//{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                // let link = document.createElement('a')
                // link.style.display = 'none'
                // link.href = url
                // link.setAttribute('download', fileName)//+'.xls'
                // document.body.appendChild(link)
                // link.click()
                // document.body.removeChild(link); //下载完成移除元素
                // window.URL.revokeObjectURL(url); //释放掉blob对象


                var reader = new FileReader();
                reader.readAsText(res.data, "utf-8");
                reader.onload = (e) => {
                    const blob = new Blob([res.data]);
                    const objectURL = URL.createObjectURL(blob);
                    let btn = document.createElement("a");
                    btn.download = fileName;
                    btn.href = objectURL;
                    btn.click();

                    URL.revokeObjectURL(objectURL);
                    btn = null;
                };
            }
        })
    },
   
    
  }
};
</script>
<style scoped lang="less">

 .container_ {
    width: 100%;
    margin: 0 auto;
    border-radius: 4px;
    text-align: left;
    .topTitle{
        font-size: 22px;
        color: #002c85;
        font-weight: 600;
        letter-spacing: 0;
        margin-bottom: 20px;
    }
    .content{
        font-size: 15px !important;
        color: #333;
        .thead{
            display: flex;
            border-bottom: 2px solid #ddd;
            font-weight: 600;
            background-color: #122b40;
            color: #EFEFEF;
        }
        .tr{
            .tr1{
                border-bottom: 1px solid #ddd;
                display: flex;
                &:hover{
                    background-color: #f5f5f5;
                }
            }
            .tr2{
                border-bottom: 1px solid #ddd;
                padding: 8px;
                word-break: break-all;
                &:hover{
                    background-color: #f5f5f5;
                }
            }
        }
        .td1{
            flex: 1;
            min-width: 80px;
            padding: 8px;
            word-break: break-all;
        }
        .td2{
            width:90px;
            padding: 8px;
        }
        .td3{
            width:90px;
            padding: 8px;
        }
        .td4{
            width:90px;
            padding: 8px;
        }

        .downloadClass{
            display: flex;
            justify-content: center;    
            margin: 20px 0px;
            .downloadBtn{
                min-width: 20%;
                font-weight: 600;
                font-size: 20px;
            }
        }
        .phoneType{
            width: 95%;
            margin: 0 auto;
            margin-bottom: 20px;
            font-size: 16px;
            .typeName{
                background: #1ea9fb;
                color: #fff;
                text-align: center;
                font-size: 20px;
                line-height: 36px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .typeContent{
                height: 100%;
                display: flex;
                padding: 10px;
                box-sizing: border-box;
                flex-wrap: wrap;
                box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
                .phoneItem{
                    width: 50%;
                    text-align: center;
                    margin: 10px 0;
                }
            }
        }
        .relaResource{
            font-size: 15px !important;
            color: #333;
            .rela_th{
                display: flex;
                border-bottom: 2px solid #ddd;
                font-weight: 600;
                font-size: 16px;
            }
            .rela_tr{
                display: flex;
                border-bottom: 1px solid #ddd;
                .rela_td1{
                    color: #337ab7;
                    cursor: pointer;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            .rela_td1{
                width:140px;
                padding: 8px;
                word-break: break-all;
            }
            .rela_td2{
                flex: 1;
                padding: 8px;
                word-break: break-all;
            }
        }
    }
 }
@media screen and (max-width: 768px) {
    .container_ .content .td1 {
        padding: 4px
    }
    .container_ .content .td2 {
        padding: 4px;
    }
    .container_ .content .td3{
        padding: 4px;
    }
    .container_ .content .td4{
        padding: 4px;
    }
    .rela_td1{
        padding: 4px !important;
    }
    .rela_td2{
        padding: 4px !important;
    }
}

</style>